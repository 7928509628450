<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<div class="filter-item">
				<el-button type="primary" style="width:120px;" @click="toCouponList()">优惠券管理</el-button>
			</div>
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">优惠券名称:</label>
				<el-input v-model="couponName" style="width: 200px;" placeholder="优惠券名称"></el-input>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table
				height="450"
				:data="tableDataList"
				v-loading="loading"
				style="width: 100%"
				ref="compSelectProTable"
				:row-key="getRowKeys"
				@selection-change="handleSelectionChange"
			>
				<el-table-column :selectable="checkSelect" type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column prop="couponName" label="优惠券名称" width="200"></el-table-column>
				<el-table-column label="适用商品">
					<template slot-scope="scope">
						<div v-if="scope.row.applyType==0">全部商品适用</div>
						<div v-if="scope.row.applyType==1">指定商品适用</div>
						<div v-if="scope.row.applyType==2">指定商品不适用</div>
					</template>
				</el-table-column>
				<el-table-column label="适用门槛">
					<template slot-scope="scope">
						<div v-if="scope.row.shold==0">无门槛使用</div>
						<div v-if="scope.row.shold==1">消费满{{scope.row.sholdMoney}}元可用</div>
					</template>
				</el-table-column>
				<el-table-column prop="faceMoney" label="优惠券面额"></el-table-column>
				<el-table-column label="领取限制">
					<template slot-scope="scope">
						<div v-if="scope.row.getType==0">不限制次数</div>
						<div v-if="scope.row.getType==1">每人限领{{scope.row.getNum}}次</div>
					</template>
				</el-table-column>
				<el-table-column prop="num" label="库存剩余"></el-table-column>
				<el-table-column label="不可选原因">
					<template slot-scope="scope">
						<div v-if="scope.row.cardType">已过期</div>
						<div v-else>——</div>
					</template>
				</el-table-column>
			</el-table>
				<el-checkbox
				style="margin-top:25px;margin-left:13px;float:left;"
				:value="checkedAll"
				@change="selectCurrentAll"
			>当前页全选</el-checkbox>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.current"
				:page-sizes="[5,10, 15, 20, 25, 30]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
import popGoods from "@/api/popGoods.js";
import config from '@/config/index'

export default {
	name: 'selectProduce',
	props: {
		api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
		selectedData: { //需要选中的商品列表
			type: Array,
			default: () => {
				return []
			}
		},
		params: { //自定义请求参数
			type: Array,
			default: () => {
				return []
			}
		},
		isShowCheck: {
			type: Boolean,
			default: () => {
				return true
			}
		},
		isShowSpecValue: {
			type: Boolean,
			default: () => {
				return false
			}
		},
		isCheckMore: {
			type: Boolean,
			default: () => {
				return false
			}
		},
	},
	data () {
		return {
			baseAPI: config.BASE_URL,
			imgUrl: config.IMG_BASE,
			couponName: '',
			searchCode: '',
			group: null,
			groupOptions: [],
			brand: null,
			brandOptions: [],
			page: {
				total: 0,
				current: 1,
				size: 10
			},
			tableDataList: [],
			selectCount: 0,
			multipleSelection: [],
			checkedShopPro: true,
			loading: false
		}
	},
	created () {
		this.getSingleList()

	},
	watch: {},
	computed: {
		checkedAll () {
			var val = this.multipleSelection;
			let ableSelectLength = 0 //获取当前页可选的全部页数
			var ids = val.map(item => {
				return item.couponId
			})
			this.tableDataList.forEach(item => {
				if (ids.indexOf(item.couponId) > -1) {
					ableSelectLength++;
				}
			});
			return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength) ? true : false;
		}
	},
	methods: {
		async getSingleList () {
			this.loading = true;
			try {
				let data = {
					pageNo: this.page.current, // 略过数据
					pageSize: this.page.size, // 取的数据
					couponName:this.couponName,
				};
				let result = await popGoods.vipBatchCouponList(data);
				this.page.total = result.data.total;
				result.data.records.map(item=>{
					item.giveCount='';
					return item;
				})
				this.tableDataList = result.data.records;

			} catch (error) {
				console.log(error);
			} finally {
				if (!this.isReady) {
					this.selectedData.map(item => {
						this.$refs['compSelectProTable'].toggleRowSelection(item, true);
					})
					this.isReady = true;
				}
				this.loading = false;
			}
		},
		//外部全选当前页
		selectCurrentAll (e) {
			this.$refs['compSelectProTable'].toggleAllSelection();
			console.log(e);
		},
		//当前页全选
		handleSelectionChange (val) {
			this.multipleSelection = val;

		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getSingleList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.current = val;
			this.getSingleList()
		},
		clearSelection () {
			this.$nextTick(() => {
				this.$refs.compSelectProTable.clearSelection();
			});
		},
		getRowKeys (row) {
			return row.couponId;
		},
		handleFilter () {
			// this.multipleSelection=[];
			console.log(this.group);
			this.page.current = 1;
			this.getSingleList();
		},
		//关闭弹框，以及曝光选择的列表
		saveSelectProList () {
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: '请选择优惠券',
					type: 'info'
				})
				return
			}
			this.$emit('getSelectList', this.multipleSelection)
		},
		/**
		 * row：当前行数据
		 * index：当前第几位
		 */
		checkSelect(row) {
			let isChecked = true;
			if (!row.cardType) { // 判断里面是否存在某个参数
				isChecked = true
			} else {
				isChecked = false
			}
			return isChecked
		},
		toCouponList(){
			this.$router.push({
				path: '/market/coupon/index',
				query: {}
			})
		}
	}
}
</script>
<style lang="less" scoped>
.contanier {
	position: relative;
	overflow: hidden;
}

.content {
	overflow: hidden;
}

.dialog-footer {
	margin-top: 20px;
	text-align: center;

	.button {
		width: 150px;
	}
}

.dialog-name-content {
	margin: 10px 0;
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}
}
.noImgIcon {
	width: 60px;
	height: 60px;
}
</style>
