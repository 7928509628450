<template>
	<div style="background: #fff;padding: 15px;margin-bottom: 200px;" v-loading="loading" :element-loading-text="loadingTitle">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
			<el-form-item label="选择优惠券:" prop="couponList">
				<el-button icon="el-icon-plus" @click="choseCoupon">添加优惠券</el-button>
				<div style="font-size: 12px;color: #666;">优惠券将直接发放给客户，不需要领取</div>
			</el-form-item>
			<el-table :data="ruleForm.couponList"
				style="width: 70%;margin-left: 100px;margin-bottom: 30px;">
				<el-table-column prop="couponName" label="优惠券"></el-table-column>
				<el-table-column label="适用门槛">
					<template slot-scope="scope">
						<div v-if="scope.row.shold==0">无门槛使用</div>
						<div v-if="scope.row.shold==1">消费满{{scope.row.sholdMoney}}元可用</div>
					</template>
				</el-table-column>
				<el-table-column prop="faceMoney" label="优惠券面额"></el-table-column>
				<el-table-column prop="num" label="库存（张）"></el-table-column>
				<el-table-column label="单人赠券数" width="200px">
					<template slot-scope="scope">
						<el-input style="width: 150px;" v-model.number="scope.row.giveCount" placeholder="可选值1~200" onkeyup="this.value=this.value.replace(/\D/g,'')"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="120">
					<template slot-scope="scope">
						<el-button type="text" style="color: #409EFF;" @click="delCoupon(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-form-item label="发放人群:" prop="groupRadio">
				<div>
					<el-radio @change="radioChange" v-model="ruleForm.groupRadio" label="1">用户等级</el-radio>
					<el-radio @change="radioChange" v-model="ruleForm.groupRadio" label="2">批量导入发放用户</el-radio>
				</div>
			</el-form-item>
			<el-form-item label-width="100px" v-if="ruleForm.groupRadio=='1'" prop="levelList">
				<el-select style="width: 400px;" v-model="ruleForm.levelList" size="small" multiple
					placeholder="请选择">
					<el-option v-for="item in roleOptions" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item v-if="ruleForm.groupRadio=='2'" label="" label-width="100px" prop="fileList">
				<div style="display: flex;align-items: center;">
					<el-button type="primary" size="mini" icon="el-icon-tickets" @click="exportListIn">选取文件</el-button>
					<input style="display: none;" type="file" ref="exportFile" @change="getFile($event)"
						class="add-file-right-input" accept=".docx,.doc,.xls,.xlsx">
					<span class="el-upload__tip" style="margin-left: 10px">*只能上传xlsx/xls文件</span>
					<div @click="downloadFail" style="margin-left: 20px;font-size: 14px;color: #409eff;margin-top: 4px;">模板下载</div>
				</div>
				<ul class="el-upload-list el-upload-list--text" style="width: 600px;">
					<li class="el-upload-list__item is-success" v-for="(item, index) in ruleForm.fileList" :key="index">
						<a class="el-upload-list__item-name">
							<i class="el-icon-document"></i>{{ item.name }}
						</a>
						<label class="el-upload-list__item-status-label">
							<i class="el-icon-upload-success el-icon-circle-check"></i>
						</label>
						<i class="el-icon-close" @click="deleteFile(index)"></i>
					</li>
				</ul>
			</el-form-item>
		</el-form>
		<el-dialog title="选择优惠券" :visible.sync="selectProDialog" width="1300px" class="dialog">
			<coupon-select
				:selectedData="ruleForm.couponList"
				@getSelectList="getSelectList"
				:visible.sync="selectProDialog"
				v-if="selectProDialog"
			></coupon-select>
		</el-dialog>
		<div class="bottom-save-btn">
			<div style="background: #fff;padding: 15px;display: flex;justify-content: left;padding-left: 50px;">
				<el-button style="width: 100px;" @click="handleBack">取消</el-button>
				<el-button style="width: 100px;margin-left: 40px;" type="primary" @click="saveBtn" :loading="loading">确定发放</el-button>
			</div>
		</div>
		<el-dialog title="导入失败记录" :visible.sync="exportFailDialog" width="1000px" class="dialog">
			<el-table :data="failList"
				style="width: 100%;margin-bottom: 20px;height: 450px;">
				<el-table-column prop="phoneNum" label="手机号"></el-table-column>
				<el-table-column label="导入状态">
					<template slot-scope="scope">
						<div>{{scope.row.importStatus}}</div>
					</template>
				</el-table-column>
				<el-table-column label="描述">
					<template slot-scope="scope">
						<div>{{scope.row.desc}}</div>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
		<el-dialog title="发放说明" :visible.sync="sendBackDialog" width="1000px" class="dialog">
			<el-table :data="sendBackData"
				style="width: 100%;margin-bottom: 30px;">
				<el-table-column prop="couponName" label="优惠券名称"></el-table-column>
				<el-table-column prop="sendUserNum" label="发放用户数量"></el-table-column>
				<el-table-column prop="sendNums" label="单个用户赠券数"></el-table-column>
				<el-table-column prop="couponStock" label="优惠券库存"></el-table-column>
				<el-table-column label="说明">
					<template slot-scope="scope">
						<div v-if="scope.row.success" style="color: #1abc9c;">{{scope.row.explain}}</div>
						<div v-else style="color: #d9001b;">{{scope.row.explain}}</div>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		// batchSendCoupon,
		templatebeforeimport,
		batchSendFileCoupon,
	} from '@/api/wyUsedInterface.js';
	import config from '@/config/index'
	import couponSelect from './vipBatchCouponSelect'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions,
			couponSelect
		},
		data() {
			return {
				ruleForm: {
					couponList: [],//选择的优惠券
					levelList: [],//选择的等级
					groupRadio: '1',//发放人群类型 1用户等级 2 导入文件
					fileList:[],//选择的文件
				},
				rules: {
					couponList: [{
						type: 'array',
						required: true,
						message: '请选择优惠券',
						trigger: 'change'
					}],
					levelList: [{
						type: 'array',
						required: true,
						message: '请选择用户等级',
						trigger: 'change'
					}],
					groupRadio: [{
						required: true,
						trigger: 'blur',
						message: '请选择商品',
					}],
					fileList: [{
						type: 'array',
						required: true,
						message: '请选择要导入的文件',
						trigger: 'change'
					}],
				},
				roleOptions: [{
						value: '0',
						label: '粉丝'
					},
					{
						value: '4',
						label: '4级'
					},
					{
						value: '3',
						label: '3级'
					},
					{
						value: '2',
						label: '2级'
					},
					{
						value: '1',
						label: '1级'
					}
				],
				loading:false,
				selectProDialog:false,//选择优惠券弹窗
				exportFailDialog:false,//导入失败记录弹窗
				failList:[],//导入失败的数据
				sendBackData:[],//发放结果
				sendBackDialog:false,//发放结果弹窗
				exportUrl:config.EXPORT_URL,
				loadingTitle:'文件处理中...'
			};
		},
		created() {

		},
		beforeMount() {

		},
		methods: {
			radioChange(val){
				if(val=='1'){
					this.$refs.ruleForm.validateField('levelList');
				}else{
					this.$refs.ruleForm.validateField('fileList');
				}
				console.log("------",val);
			},
			//删除选择的优惠券
			delCoupon(row) {
				var list = [];
				this.ruleForm.couponList.map(item=>{
					if(item.couponId!=row.couponId){
						list.push(item);
					}
					return item;
				})
				this.ruleForm.couponList = list;
			},
			exportListIn() {
				this.$refs.exportFile.click();
			},
			getFile(e) {
				let list = [e.target.files[0]];
				this.ruleForm.fileList=list;
				this.$refs.exportFile.value="";//清除选择后的文件
				this.$refs.ruleForm.validateField('fileList');
				if(this.ruleForm.fileList.length){
					this.loading = true;
					this.loadingTitle = "文件处理中...";
					this.submitUpload();
				}
			},
			deleteFile(index) {
				this.ruleForm.fileList.splice(index, 1);
			},
			async submitUpload() {
				let data = {
					file:this.ruleForm.fileList[0]
				};
				let _this = this;
				console.log("----data----",data)
				let res = await templatebeforeimport(data);
				if(res.success){
					setTimeout(function(){
						_this.loading = false;
						_this.failList = res.data;
						if(_this.failList.length){
							_this.exportFailDialog = true;
						}
					},1500)
				}else{
					setTimeout(function(){
						_this.loading = false;
						_this.$message({
							type: 'error',
							message: res.alertMsg
						});
					},1500)
				}
			},
			saveBtn(){
				this.$refs['ruleForm'].validate((valid)=>{
					if(valid){
						var isInput = true;
						var isOverNum = false;
						this.ruleForm.couponList.map(item=>{
							if(!item.giveCount){
								isInput = false;
							}else{
								if(item.giveCount>200 ||item.giveCount<0){
									isOverNum = true;
								}
							}
							return item;
						})
						if(!isInput){
							this.$message({
								type: 'error',
								message: '请填写发放数量'
							});
							return;
						}
						if(isOverNum){
							this.$message({
								type: 'error',
								message: '优惠券赠送数量限制在1~200张之间'
							});
							return;
						}
						this.sendCouponBefore();
					}else{
						console.log('error submit!!');
						return false;
					}
				})
			},
			//返回
			handleBack(){
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: '/Customer/vipList'
				});
			},
			//选择优惠券弹窗
			choseCoupon(){
				this.selectProDialog = true;
			},
			//返回选择的优惠券
			getSelectList(val){
				this.ruleForm.couponList = val;
				this.selectProDialog = false;
				this.$refs.ruleForm.validateField('couponList');
				this.$forceUpdate();
			},
			//确定发放接口
			async batchSendCoupon(){
				var list = [];
				this.ruleForm.couponList.map(item=>{
					var obj = {
						couponId:item.couponId,
						sendNums:item.giveCount,
					}
					list.push(obj);
					return item;
				})
				var level = [];
				this.ruleForm.levelList.map(item=>{
					level.push(parseInt(item));
					return item;
				})
				let data = {
					operateType:+this.ruleForm.groupRadio,//操作类型 1：用户等级 2：导入用户
					couponList:JSON.stringify(list),
				}
				if(this.ruleForm.groupRadio=='1'){
					data.levelList=level;
				}else{
					data.file=this.ruleForm.fileList[0];
				}
				let res = null;
				res = await batchSendFileCoupon(data);
				// if(this.ruleForm.groupRadio=='1'){
				// 	res = await batchSendCoupon(data);
				// }else{
				// 	res = await batchSendFileCoupon(data);
				// }
				if(res.success){
					this.loading = false;
					this.sendBackData = res.data;
					this.sendBackDialog = true;
				}else{
					this.loading = false;
					this.sendBackDialog = false;
					this.$message({
						type: 'error',
						message: res.alertMsg
					});
				}
			},
			// 下载模板
			downloadFail() {
				let url = this.exportUrl + '/admin/userinfo/template/download'
				window.open(url)
			},
			sendCouponBefore(){
				this.$confirm('您确定要针对所选人群发放优惠券吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.loading = true;
					this.loadingTitle = "发放中请耐心等待...";
					this.batchSendCoupon();
				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.bottom-save-btn {
		position: fixed;
		width: 89%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}
</style>
